<template>
  <button
    class="table-btn"
    :class="[
      `${color}-btn`,
      activated ? `${color}-btn-active` : `${color}-btn`,
    ]"
    @click="getTable"
  >
    {{ option.content }}
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: ['option', 'activated', 'color'],
  emits: ['isActive', 'getTable'],
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    getTable() {
      this.isActive = !this.isActive;
      if (this.isActive) {
        this.$emit('isActive');
        this.$emit('getTable');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-btn {
  margin-right: 0.5rem;
  height: fit-content;
}
</style>
