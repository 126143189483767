<template>
  <div class="btn-group mt-2 btn-center">
    <div v-for="option in card.options" :key="option.id">
      <ButtonItem
        :option="option"
        @isActive="activated = option.id"
        :activated="activated === option.id"
        @getTable="getChart(option)"
        :color="card.color"
      />
    </div>
  </div>
  <div class="row" v-if="charts?.length && showChart">
    <div v-for="chart in charts" :key="chart.title" class="mb-4">
      <p class="indicator-label text-center mt-4 mb-4">{{ chart.title }}</p>
      <BarChart
        :chartData="chart.data"
        :factsheet="true"
        :highlighted="false"
      />
      <ChartLegend :legend="chart.legend" />
    </div>
  </div>
</template>

<script>
import ButtonItem from '@/components/utils/ButtonItem';
import BarChart from '@/components/charts/BarChart';
import ChartLegend from '@/components/charts/ChartLegend';
import Api from '@/utils/services/Api';

export default {
  name: 'ButtonsType',
  props: ['card', 'activeBranch'],
  components: {
    ButtonItem,
    BarChart,
    ChartLegend,
  },
  data() {
    return {
      activated: null,
      showChart: false,
      showChartArr: false,
      data: [],
      legend: [],
      charts: [],
      indicator: {},
    };
  },
  methods: {
    toggleChart() {
      this.showChart = !this.showChart;
      this.showPrevention = false;
    },
    async getChart(option) {
      this.charts = [];
      this.showChart = false;
      this.isActive = false;
      this.showPrevention = false;

      const data = await Api.post(
        'charts',
        { type: 'multiple' },
        option.indicators
      );

      this.charts = [
        {
          title: option.content,
          data: data.series,
          legend: data.legend,
        },
      ];

      this.isActive = true;
      this.showChart = true;
    },
  },
};
</script>
