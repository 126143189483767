<template>
  <div class="row mb-4 mt-4">
    <div
      class="col-12"
      :class="`col-md-${card.size}`"
      v-for="card in cards"
      :key="card.id"
    >
      <template
        v-if="
          card.type === 'TableType' ||
          card.type === 'ChartType' ||
          card.type === 'OptionsType' ||
          card.type === 'ButtonsType'
        "
      >
        <component :is="card.type" :card="card" :activeBranch="activeBranch" />
      </template>
      <component
        :is="card.type"
        v-else
        :card="card"
        @getNext="getNext(card)"
        @getPrevention="getPrevention(card)"
        @isActive="activated = card.id"
        :activated="activated === card.id"
      />
    </div>
  </div>
</template>

<script>
import BoxType from '@/components/types/BoxType';
import CardType from '@/components/types/CardType';
import TableType from '@/components/types/TableType';
import ChartType from '@/components/types/ChartType';
import OptionsType from '@/components/types/OptionsType';
import ButtonsType from '@/components/types/ButtonsType';
import InfoType from '@/components/types/InfoType';
import Api from '@/utils/services/Api';

export default {
  name: 'LevelRow',
  props: ['cards', 'level', 'activeBranch'],
  emits: ['next', 'prevention'],
  components: {
    BoxType,
    CardType,
    TableType,
    ChartType,
    OptionsType,
    ButtonsType,
    InfoType,
  },
  data() {
    return {
      activated: null,
    };
  },
  methods: {
    async getNext(card) {
      let causes = await Api.post(
        'causes',
        { type: 'exploration' },
        { options: card.causes }
      );

      if (!causes.length) causes = this.getPlaceholder(card, 'causes');

      this.$emit('next', causes, this.level, card.id);
    },
    async getPrevention(card) {
      let preventions = await Api.post(
        'preventions',
        { type: 'exploration' },
        { options: card.preventions, branch: this.activeBranch }
      );

      if (!preventions.length)
        preventions = this.getPlaceholder(card, 'preventions');
      this.$emit('prevention', preventions, this.level);
    },
    getPlaceholder(card, type) {
      return [
        {
          id: `${card.id}-placeholder`,
          type: 'InfoType',
          color: type === 'causes' ? 'purple' : 'green',
          btnLeft: null,
          btnRight: null,
          content: card?.placeholder || 'Coming soon',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  .col-12 {
    margin: 0 auto;
  }
}
</style>
