<template>
  <div class="btn-group mt-2 btn-center options-buttons">
    <div v-for="option in card.options" :key="option.id">
      <ButtonItem
        :option="option"
        @isActive="activated = option.id"
        :activated="activated === option.id"
        @getTable="getChart(option)"
        :color="card.color"
      />
    </div>
  </div>
  <div class="row" v-if="activated">
    <div class="mt-4 btn-group btn-right">
      <button
        class="card-btn"
        :class="[showChart ? 'orange-btn-active' : 'orange-btn']"
        @click="toggleChart"
      >
        What materials does this concern?
      </button>
      <button
        class="card-btn"
        :class="[showPrevention ? 'green-btn-active' : 'green-btn']"
        @click="togglePrevention"
      >
        How can this be prevented?
      </button>
    </div>
    <template v-if="charts?.length && showChart">
      <div v-for="chart in charts" :key="chart.title" class="mb-4">
        <p class="indicator-label text-center mt-4 mb-4">{{ chart.title }}</p>
        <BarChart
          :chartData="chart.data"
          :factsheet="true"
          :highlighted="false"
          v-if="activeOption.id !== 'natural_disasters'"
        />
        <ChartLegend
          :legend="chart.legend"
          :warning="activeOption.id === 'natural_disasters' ? false : true"
        />
      </div>
    </template>
    <template v-if="showPrevention">
      <div
        class="row"
        v-for="level in levels"
        :key="level.level_number"
        :class="[level.level_number === 1 ? 'mb-4 mt-4' : '']"
      >
        <div
          class="col-12"
          v-for="prev in level.cards"
          :class="`col-md-${prev.size}`"
          :key="prev.id"
        >
          <BoxType
            v-if="prev.type === 'BoxType'"
            :card="prev"
            @getPrevention="getTable(prev.preventions)"
            @isActive="activeLevel = prev.id"
            :activated="activeLevel === prev.id"
          />
          <TableType v-else :card="prev" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ButtonItem from '@/components/utils/ButtonItem';
import BarChart from '@/components/charts/BarChart';
import ChartLegend from '@/components/charts/ChartLegend';
import BoxType from '@/components/types/BoxType';
import TableType from '@/components/types/TableType';

import Api from '@/utils/services/Api';

export default {
  name: 'OptionsType',
  props: ['card', 'activeBranch'],
  components: {
    ButtonItem,
    BarChart,
    ChartLegend,
    BoxType,
    TableType,
  },
  data() {
    return {
      activated: null,
      showChart: false,
      showChartArr: false,
      data: [],
      legend: [],
      charts: [],
      indicator: {},
      showPrevention: false,
      activeOption: {},
      levels: [{ level_number: 1, cards: [] }],
      activeLevel: null,
    };
  },
  methods: {
    async getPrevention() {
      const preventions = await Api.post(
        'preventions',
        { type: 'exploration' },
        { options: this.activeOption.preventions, branch: this.activeBranch }
      );
      this.levels = [{ level_number: 1, cards: preventions }];
    },
    async togglePrevention() {
      this.showChart = false;
      this.showPrevention = !this.showPrevention;

      if (this.showPrevention) await this.getPrevention();
    },
    async getTable(options) {
      this.levels.length = 1;
      await this.$nextTick();
      const prevention = await Api.post(
        'preventions',
        { type: 'exploration' },
        { options: options, branch: this.activeBranch }
      );
      this.levels.push({
        level: 2,
        cards: prevention,
      });
    },
    toggleChart() {
      this.showChart = !this.showChart;
      this.showPrevention = false;
    },
    async getChart(option) {
      this.activeOption = option;

      this.charts = [];
      this.showChart = false;
      this.isActive = false;
      this.showPrevention = false;

      const data = await Api.post(
        'charts',
        { type: 'multiple' },
        option.indicators
      );
      this.charts = [
        {
          title: option.content,
          data: data.series,
          legend: data.legend,
        },
      ];

      this.isActive = true;
      this.showChart = true;
    },
  },
};
</script>

<style lang="scss">
@include bp-down(lg) {
  .options-buttons {
    flex-wrap: wrap;
    button {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
