<template>
  <div class="container-fluid exploration-container">
    <BackRow />
    <div class="row">
      <h2 class="blue-title mb-4 text-center">
        What are you concerned about ?
      </h2>
    </div>
    <LevelRow
      v-for="level in levels"
      :key="level.level_number"
      @next="getNextLevel"
      @prevention="getNextLevel"
      :cards="level.cards"
      :level="level.level_number"
      :activeBranch="activeBranch"
    />
  </div>
</template>

<script>
import LevelRow from '@/components/exploration/LevelRow';
import BackRow from '@/components/utils/BackRow';
import Api from '@/utils/services/Api';
import { ElLoading } from 'element-plus';

export default {
  name: 'Exploration',
  components: {
    LevelRow,
    BackRow,
  },
  data() {
    return {
      levels: [],
      showLevel: true,
      isLoading: false,
      activeBranch: null
    };
  },
  async created() {
    const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0)',
      });
    const concerns = await Api.get('concerns', 'exploration');
    this.levels = [{ level_number: 1, cards: concerns }];
    this.isLoading = false;
    loading.close()
  },
  methods: {
    async getNextLevel(options, level, card_id) {

      if (level === 1)  this.activeBranch = card_id;

      this.levels.length = level;
      await this.$nextTick();

      this.levels[level] = {
        level_number: level + 1,
        cards: options,
      };

      this.levels.length = level + 1;
    }
  },
};
</script>

<style lang="scss">
.exploration-container {
  width: 87% !important;
  min-height: 30rem;
}
@include bp-down(xxl) {
  .exploration-container {
    width: 95% !important;
  }
}
@include bp-down(lg) {
  .exploration-container {
    width: 95% !important;
  }
}
</style>
